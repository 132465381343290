<template>
    <div>
        <Table
            ref="table"
            :columnConfig="tableColumnConfig"
            :rows="tableShowData"
            :isLoading="isLoading"
            :sortDefault="sortConfig"
            :isPagination="true"
            :excelConfig="excelConfig"
            :toolbox="['columnSetting', 'excel', 'zooming']"
            :isFocusRow="true"
            :descriptions="descriptions"
            :hiddenColumnDefault="hiddenCols"
            :columnsMode="columnsMode"
            :isFixedHeader="true"
        >
            <template v-slot:contestFyb="{ isHeader, row }">
                <div v-if="!isHeader">
                    {{ this.$numberWithComma(row.contestFyb) }} 元
                    <div
                        v-if="
                            getFybTips(row.contestCustomRate) &&
                            row.contestCustomRate
                        "
                        class="info-icon"
                        :tooltip="getFybTips(row.contestCustomRate)"
                        tooltip-touch
                    />
                </div>
            </template>
        </Table>
    </div>
</template>

<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import {
    contestColumns,
    contestDescriptions
} from '@/assets/javascripts/report/setting'

export default {
    name: 'ContestTable',
    components: {
        Table
    },
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        },
        contestInfo: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: true
        },
        isSelf: {
            type: Boolean,
            default: false
        },
        isCeoMode: {
            type: Boolean,
            default: false
        },
        infos: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    methods: {
        transformData: function (rows) {
            /* ***
             * 序列號依照業績排，套用篩選要重編號、排序不重編號。
             * 故不能進Table元件才依照繼續率排序，要直接改寫資料源的排序。
             *** */
            const self = this.isSelf
            return rows
                .sort(function (a, b) {
                    if (self) {
                        return new Date(b.receiveDate) - new Date(a.receiveDate)
                    } else {
                        return b.contestFyb - a.contestFyb
                    }
                })
                .map((row, index) => {
                    row.serialNumber = index + 1
                    row.receiveDate = row?.receiveDate?.slice(0, 10)
                    row.fourWinRate = {
                        value: row.fourWinRate,
                        unit: row.fourWinRateUnit
                    }
                    row.insureWayRate = {
                        value: row.insureWayRate,
                        unit: row.insureWayRateUnit
                    }
                    row.isSpecial = row.isSpecial ? '是' : '否'

                    return row
                })
        },
        getFybTips: function (contestCustomRate) {
            const contestId = this.contestInfo.contestId

            const tipList = {
                3: '本筆競賽業績依「2022 磊山高峰會『嬉遊記』旅遊競賽」獎勵辦法補充說明提供額外加成',
                7: '本筆競賽業績依「2024 磊山寰宇會議『化釜濟為神奇』旅遊競賽獎勵辦法」提供額外加成'
            }
            let tip = ''
            //自訂比率加成不為100%時表示加成有改變，需顯示tooltips提醒
            if (
                this.isSelf &&
                contestCustomRate !== 100 &&
                Object.prototype.hasOwnProperty.call(tipList, contestId)
            ) {
                tip = tipList[contestId]
            }

            return tip
        }
    },
    computed: {
        tableColumnConfig: function () {
            let columns = _.cloneDeep(contestColumns)
            let removeColumns
            if (this.isSelf) {
                removeColumns = ['score', 'leaderScore', 'relativeDepth']
            } else {
                removeColumns = [
                    'productName',
                    'insNo',
                    'receiveDate',
                    'fourWinRate',
                    'insureWayRate',
                    'isSpecial',
                    'yPeriod'
                ]
                if (!this.isCeoMode) {
                    removeColumns.push('leaderScore')
                }
                const scoreTitle = this.contestInfo.scoreTitle
                columns.score.label = scoreTitle
                columns.leaderScore.label = `主管獎總${scoreTitle}`
                if (scoreTitle?.length > 2) {
                    const count = scoreTitle?.length
                    columns.score.widthPx = 16 * count + 20
                    columns.leaderScore.widthPx = 16 * (count + 4) + 20
                }
            }
            columns = _.omit(columns, removeColumns)

            return columns
        },
        tableShowData: function () {
            if (this.listData.length === 0) return []
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        descriptions: function () {
            return contestDescriptions(
                this.isCeoMode,
                this.contestInfo.scoreTitle,
                this.contestInfo.contestId
            )
        },
        excelFileName: function () {
            let contest = this.filterLog?.contestId?.label
            let name = this.filterLog?.manCode?.label
            let title =
                this.filterLog.relationMode?.value === 'self'
                    ? `${name}_本人`
                    : `${name}_組織`

            if (this.filterLog.role?.value === 'ceo') {
                title = this.filterLog.bgCode?.label
            }
            return `${contest}_${title}競賽業績`
        },
        excelConfig: function () {
            return {
                fileName: this.excelFileName,
                filters: this.filterLog,
                descriptions: this.descriptions,
                infos: this.infos
            }
        },
        sortConfig: function () {
            return this.isSelf
                ? { sort: 'desc', sortBy: 'receiveDate' }
                : { sort: 'desc', sortBy: 'contestFyb' }
        },
        hiddenCols: function () {
            return this.isSelf
                ? ['bgName', 'orgBmGroupName', 'orgBmName', 'salesName']
                : this.isCeoMode
                ? ['relativeDepth']
                : []
        },
        columnsMode: function () {
            return this.isSelf
                ? 'self'
                : this.isCeoMode
                ? 'ceoOrg'
                : 'normalOrg'
        }
    },
    data() {
        return {
            inoCount: 0,
            sumCardSet: null
        }
    },
    mounted() {
        let table = this.$refs?.table
        this.$watch(
            function () {
                return table.tableShowData
            },
            function () {
                this.$reloadTips()
            },
            {
                deep: true
            }
        )
    }
}
</script>

<style lang="scss" scoped>
:deep(.card) {
    @media screen and (max-width: 576px) {
        .row-container .row.body {
            &.loading {
                .serial-number,
                .sales-name,
                .sales-title,
                .relative-depth {
                    display: none;
                }
            }
            &.sum-row {
                .bg-name,
                .org-bm-group-name,
                .org-bm-name,
                .sales-name,
                .ap-stu-name,
                .sales-title,
                .relative-depth,
                .count,
                .supplier,
                .ins-no,
                .receive-date,
                .effect-date,
                .job-type,
                .y-period,
                .pay-way,
                .score,
                .leader-score,
                .product-name,
                .four-win-rate,
                .insure-way-rate,
                .is-special {
                    display: none;
                }
            }
            .cell {
                &.serial-number {
                    order: -10;
                    font-weight: 600;
                    margin-right: 5px;
                    width: auto;
                    &:before {
                        display: none;
                    }
                    &:after {
                        content: '.';
                    }
                }
                &.sales-name {
                    order: -9;
                    width: auto;
                    margin-right: 5px;
                    font-weight: 600;
                    &:before {
                        display: none;
                    }
                }
                &.sales-title {
                    order: -8;
                    width: auto;
                    border-bottom: none;
                    padding: 0;
                    margin-right: 5px;
                    font-weight: 600;
                    &:before {
                        display: none;
                    }
                }
                &.level {
                    order: -7;
                    width: auto;
                    font-weight: 600;
                    &:before {
                        content: '第';
                        margin-right: 0;
                        font-size: 14px;
                        color: $sixth-black;
                    }
                    &:after {
                        content: '代';
                    }
                }
                &.ap-stu-name {
                    padding-bottom: 10px;
                    border-bottom: 1px dashed $fourth-grey;
                }
            }
        }
    }
}
:deep(.tool-option) {
    &.full-size {
        display: flex;
        @media screen and (max-width: 576px) {
            display: none !important;
        }
    }
}

:deep(.contest-fyb) {
    position: relative;
    margin-right: 10px;
    .info-icon {
        position: absolute;
        right: -8px;
        top: calc(50% - 7px);
        margin-left: 5px;
        width: 14px;
        height: 14px;
        @media screen and (max-width: 576px) {
            right: auto;
            top: 3px;
            left: 55px;
        }
    }
}

.info-icon {
    width: 14px;
    height: 14px;
}

:deep(p) {
    display: flex;
    color: $secondary-grey;
    font-size: 12px;
    margin: 0;
    padding: 0;
    span {
        display: inline-block;
    }
}
</style>
